import Loading from '@@/core/components/shared/Loading/Loading'
import shippingModal from '@@/core/components/shipping/ShippingForm/ShippingForm'
import ImageHolder from '@@/core/components/shared/ImageHolder/ImageHolder'
import mixins from '@@/core/mixins/mixins'
import {BTooltip, VBModal} from "bootstrap-vue";
import draggable from "vuedraggable";

export default {
  name: "Shipping",
  components: {
    Loading,
    shippingModal,
    ImageHolder,
    BTooltip,
    draggable
  },
  directives: {
    'b-modal': VBModal,
  },
  mixins: [mixins],
  data() {
    return {
      url: 'admin/shippings',
      shippings: null,
      submit_url: 'admin/shippings',
      mode: 'create',
      shipping: {
        name: '',
        status: 1,
        default_price: 1000,
        free_threshold: 1000,
        provinces: null,
        description: '',
        packet_size: 100,
        more_packet_price: 0
      },
      provinces: [],
      cities: [],
      publicValue: 0
    }
  },
  mounted() {
    this.loadItems()

  },
  computed: {
    dragOptions() {
      return {
        animation: 200,
        group: "description",
        disabled: this.disabled,
        ghostClass: "ghost"
      }
    }
  },
  methods: {
    async loadItems() {
      const response = await this.$axios.get(this.url + '?all=1')
      this.shippings = response.data.data.shippings
    },
    async editItem(id, index) {
      this.disabled = true
      try {
        this.shipping = {
          ...this.shippings.find((item) => {
            return item.id == id;
          })
        }
        this.mode = 'edit'
        this.submit_url = this.url + `/${id}`
        this.publicValue = this.shipping.provinces == '' ? 1 : 0
        this.$root.$emit('bv::show::modal', 'shipping-modal')
        this.shipping.index = index
      } catch (error) {
        this.$root.notify(error)
      } finally {
        this.disabled = false
      }
    },
    newShipping() {
      this.mode = 'create'
      this.submit_url = this.url
      this.shipping = {
        name: '',
        status: 1,
        default_price: 1000,
        free_threshold: 1000,
        provinces: null,
        minimum_delay: 0
      }
      this.publicValue = 0
    },
    async sortShipping() {
      let orders = [];

      this.shippings.forEach((item) => {
        orders.push(item.id)
      });
      try {
        this.disabled = true;
        const response = await this.$axios.post('admin/shippings/sort', {
          orders: orders,
        })
        this.$root.notify(response.data.message, 'success')
      } catch (error) {
        this.$root.notify(error)
      }
      this.disabled = false
    },
    deleteItem(id) {
      window.swal({
        title: "آیتم حذف شود؟",
        text: "این عملیات غیر قابل بازگشت خواهد بود",
        icon: "warning",

        showCancelButton: true,
        showConfirmButton: true,
        dangerMode: true,
        buttons: ["خیر", "بله حذف کن"]
      })
          .then((willDelete) => {
            if (willDelete) {
              this.$axios.delete(`admin/shippings/${id}`)
                  .then((response) => {
                    this.shippings = this.shippings.filter(item => item.id != id)
                    window.swal({
                      icon: "success",
                      showConfirmButton: true,
                      text: response.data.message,
                      button: "باشه",
                    })
                  })
                  .catch(error => {
                    window.swal({
                      title: 'خطا',
                      showConfirmButton: true,
                      text: error.response.data.message,
                      button: "باشه",
                    })
                  })
            }
          })
          .catch(() => {

          })
    },
  }
}
