<template>
    <div class="dimmer active" :class="{'absolute-loading': positionAbsolute}">
        <div class="spinner4">
            <div class="bounce1"></div>
            <div class="bounce2"></div>
            <div class="bounce3"></div>
        </div>
    </div>
</template>
<script>
import LoadingImpl from '@@/core/components/shared/Loading/LoadingImpl'
  export default {
    extends: LoadingImpl
  }
</script>
<style scoped src="@@/core/components/shared/Loading/LoadingStyle.css"></style>
